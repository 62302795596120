(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('ReportController', ReportController);

    ReportController.$inject = ['$q', '$uibModal', '$rootScope', 'Report', 'CoreService', 'GUIUtils', 'toastr',
        'PendingReport', 'GenericEntityDatatableService', 'ConfirmationService'];

    function ReportController($q, $uibModal, $rootScope, Report, CoreService, GUIUtils, toastr,
                              PendingReport, GenericEntityDatatableService, ConfirmationService) {
        var vm = this;

        var params = {
            facilityId: CoreService.getCurrentFacility().id,
            employeeId: CoreService.getCurrentEmployee().id,
            sort: 'name,asc'
        };

        vm.descriptor = {
            title: 'Reports',
            newAction: false,
            entityClassHumanized: 'report',
            entityStateName: 'report',
            service: Report,
            serviceMethod: 'findAllByFilter',
            params: params,
            pagination: 'remote',
            options: [{key: 'aaSorting', value: [[1, 'asc']]}],
            columns: [
                {
                    name: 'id',
                    title: 'ID',
                },
                {
                    name: 'name',
                    title: 'Name',
                },
                {
                    name: 'enable',
                    title: 'Enabled',
                    render: function (data) {
                        return GUIUtils.colorHtmlYesNo(data.enable);
                    }
                },
                {
                    name: null,
                    title: "Category",
                    render: function (data) {
                        return data.category.id.charAt(0) + data.category.id.replaceAll('_', ' ').slice(1).toLocaleLowerCase();
                    }
                },
                {
                    name: null,
                    title: "Created By",
                    render: function (data) {
                        return data.employee.lastName + ', ' + data.employee.firstName;
                    }
                },
                {
                    name: null,
                    title: 'Last Activity',
                    render: function (data) {
                        return data.lastActivityDate ? moment(data.lastActivityDate).format($rootScope.amDateFormat) : '-';
                    }
                },
                {
                    name: null,
                    title: 'Only Mine',
                    render: function (data) {
                        return GUIUtils.colorHtmlYesNo(data.onlyMine);
                    }
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getPlayAction(), {
                    name: 'Execute',
                    ngIf: function (data) {
                        return data.enable;
                    },
                    action: function (data) {
                        execute(data.id);
                    },
                    reload: false
                })
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getRefreshAction(), {
                            name: null,
                            action: function () {
                            }
                        })
                    ]
                },
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Report',
                            action: function () {
                                return add();
                            }
                        })
                    ]
                },
            ]
        }

        function add() {
            return $q(function (resolve) {
                $uibModal.open({
                    templateUrl: 'app/entities/report/update/report-edit-dialog.html',
                    controller: 'ReportEditDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'xl',
                    windowTopClass: 'custom-dialog-styles',
                    resolve: {
                        entity: ['CoreService', function (CoreService) {
                            return {
                                facility: CoreService.getCurrentFacility(),
                                id: null,
                                name: null,
                                enable: true,
                                onlyMine: false,
                                description: null,
                                status: 'DRAFT',
                                category: null,
                                lastActivityDate: null,
                                employee: CoreService.getCurrentEmployee(),
                                reportColumns: [],
                                reportConditions: []
                            };
                        }]
                    }
                }).result.then(function () {
                    toastr.success('Report was saved', 'Success');

                    resolve();
                });
            });
        }

        function edit(id) {
            return $q(function (resolve) {
                $uibModal.open({
                    templateUrl: 'app/entities/report/update/report-edit-dialog.html',
                    controller: 'ReportEditDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'xl',
                    windowTopClass: 'custom-dialog-styles',
                    resolve: {
                        entity: Report.get({id: id}).$promise
                    }
                }).result.then(function () {
                    toastr.success('Report was saved', 'Success');

                    resolve();
                });
            });
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Report').result.then(function () {
                    Report.delete({id: id}, function () {
                        toastr.success('Report was deleted', 'Success');

                        return resolve();
                    });
                })
            });
        }

        function execute(id) {
            PendingReport.save({
                reportId: id,
                facilityId: CoreService.getCurrentFacility().id
            }, function (result) {
                toastr.success('Your report "' + result.report.name + '" is been generated please go to storage to download it');
            }, function () {
                toastr.error("We couldn't generate the report.");
            });
        }
    }
})();
