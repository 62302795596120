(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('AuditDetailsDialogController', AuditDetailsDialogController);

    AuditDetailsDialogController.$inject = ['$uibModalInstance', 'data', 'CoreService', 'Chart', 'chart', 'GUIUtils'];

    function AuditDetailsDialogController($uibModalInstance, data, CoreService, Chart, chart, GUIUtils) {
        var vm = this;

        vm.clear = clear;

        vm.audit = data.audit[data.date];
        vm.page = 0;
        vm.itemsPerPage = 5;
        vm.dtInstance = {};
        vm.details = [];
        vm.chart = chart;
        vm.date = data.date;
        vm.getHumanHours = getHumanHours;
        vm.insurance = "";

        vm.colorHtmlYesNo = colorHtmlYesNo;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
        vm.cds = [];
        vm.gss = [];
        vm.cdsCounter = {};
        vm.gssCounter = {};
        vm.levels = "";

        _.forEach(vm.audit.details, function (detail) {
            _.forEach(detail.careDeliveryStatuses, function (care) {
                addRow(care, vm.cdsCounter, vm.cds);
            });
            _.forEach(detail.groupSessionDetailStatuses, function (group) {
                addRow(group, vm.gssCounter, vm.gss);
            });

            if (detail.insuranceCarrier) {
                vm.insurance += (vm.insurance.length > 0 ? "," : "") + detail.insuranceCarrier;
            }

            vm.levels += vm.levels.length == 0 ? detail.typeLevelCare : ("," + detail.typeLevelCare);
        });

        _.forEach(vm.audit.nobillableCd, function (care) {
            addRow(care, vm.cdsCounter, vm.cds);
        });
        _.forEach(vm.audit.nobillableGs, function (group) {
            addRow(group, vm.gssCounter, vm.gss);
        });

        function count(counter, obj) {
            if (counter[obj.status]) {
                counter[obj.status] += obj.hours;
            } else {
                counter[obj.status] = obj.hours;
            }
        }

        function getHumanHours(inSeconds) {
            if (inSeconds) {
                var result = moment.duration(Number(inSeconds), 'seconds').days();
                result = result == 0 ? "" : result + ' d ';

                var h = moment.duration(Number(inSeconds), 'seconds').hours() + '.' + moment.duration(Number(inSeconds), 'seconds').minutes();

                result += h > 0 ? h : "";

                return result;
            }

            return 0;
        }

        function addRow(row, counter, rows) {
            row["_hours"] = getHumanHours(row.hours);
            count(counter, row);
            rows.push(row);
        }

        function colorHtmlYesNo(data) {
            return GUIUtils.colorHtmlYesNo(data);
        }
    }
})();
